<template>
    <div class="appoint-query container">
        <van-form @submit="submit">
            <van-cell-group>
                <van-field center v-model="mobile" placeholder="请输入" type="tel"
                           :rules="[{ required: true, message: '请填写手机号' }]">
                    <div slot="label">
                        <div class="van-cell__title mtitle">
                            <span>手机号</span>
                            <div class="van-cell__label mlabel">Mobile Number</div>
                        </div>
                    </div>
                </van-field>
                <van-field center v-model="cardNo" placeholder="请输入"
                           :rules="[{ required: true, message: '请填写证件号' }]">
                    <div slot="label">
                        <div class="van-cell__title mtitle">
                            <span>证件号</span>
                            <div class="van-cell__label mlabel">ID No.</div>
                        </div>
                    </div>
                </van-field>
            </van-cell-group>
            <van-button type="primary" class="btn-primary" round style="margin: 100px auto 0; display: block;"
                        native-type="submit">查询
            </van-button>
        </van-form>
    </div>
</template>
<script>
    import Vue from 'vue'
    import { Field, Cell, CellGroup, Button, Form } from 'vant'
    import 'vant/lib/field/style'
    import 'vant/lib/cell/style'
    import 'vant/lib/cell-group/style'
    import 'vant/lib/form/style'
    import 'vant/lib/button/style'

    Vue.use(Field)
    Vue.use(Cell)
    Vue.use(CellGroup)
    Vue.use(Button)
    Vue.use(Form)

    export default {
        data () {
            return {
                cardNo: null,
                mobile: null
            }
        },
        methods: {
            submit () {
                let that = this
                this.$router.push({ name: 'appoints', query: { cardNo: that.cardNo, mobile: that.mobile } })
            }
        }
    }

</script>
